<template>
  <base-dialog
    width="800"
    title="Horario"
    btn_text="Editar"
    icon="$clock"
    :type="hidden ? 'hidden' : !icons ? 'block' : 'icon'"
    :no_icon="!icons"
    small
    @open="fetch"
    ref="dialog"
  >
    <v-row>
      <v-col cols="12">
        <h3 v-if="tattooer_id">
          {{ $t("timetable_active_tattooer") }}
        </h3>
        <h3 v-else-if="box_id">
          {{ $t("timetable_active_box") }}
        </h3>
        <h3 v-else>
          {{ $t("timetable_studio") }}
        </h3>

        <base-timetable
          :timetable="active"
          :timetable_type="type"
        ></base-timetable>
      </v-col>
      <v-col cols="12">
        <h3 v-if="tattooer_id">{{ $t("timetable_active_studio") }}</h3>
        <h3 v-else>{{ $t("timetable_base_studio") }}</h3>
        <base-timetable
          :timetable="default_timetable"
          :timetable_type="type"
        ></base-timetable>
      </v-col>
      <v-col cols="12">
        <h3>
          {{
            tattooer_id
              ? $t("next_timetables_tattooer")
              : box_id
              ? $t("next_timetables_box")
              : $t("next_timetables_studio")
          }}
          <Add
            :studio_id="studio_id"
            :tattooer_id="tattooer_id"
            :box_id="box_id"
            @update="fetch"
          />
        </h3>
        <base-data-table-cards
          :headers="headers"
          :headers_small="headers_small"
          title="Horario"
          :data="timetables"
        >
          <template v-slot:[`dialog_inner`]="item">
            <base-timetable :timetable="item.timetable"></base-timetable>
          </template>
          <template v-slot:[`item_concepto`]="item">
            {{ item.concepto }}
          </template>
          <template v-slot:[`item_concepto_tattooer`]="item">
            <div>{{ item.concepto }}</div>
            <div class="">
              {{ item.tattooer ? item.tattooer.user.fullname : "-" }}
            </div>
          </template>
          <template v-slot:[`item_d_start`]="item">
            {{ item.d_start ? $d(new Date(item.d_start), "dateShort") : "-" }}
          </template>

          <template v-slot:[`item_d_end`]="item">
            {{ item.d_end ? $d(new Date(item.d_end), "dateShort") : "-" }}
          </template>
          <template v-slot:[`item_d_start_d_end`]="item">
            <div>
              {{ item.d_start ? $d(new Date(item.d_start), "dateShort") : "-" }}
            </div>
            <div>
              {{ item.d_end ? $d(new Date(item.d_end), "dateShort") : "-" }}
            </div>
          </template>

          <template v-slot:[`item_tattooer`]="item">
            {{ item.tattooer ? item.tattooer.user.fullname : "-" }}
          </template>
          <template v-slot:[`item_actions`]="item">
            <base-dialog
              type="icon"
              icon="$eye"
              :title="'Ver horario ' + item.concepto"
              small
              :width="800"
            >
              <base-timetable :timetable="item.timetable"></base-timetable
            ></base-dialog>
          </template>
        </base-data-table-cards>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "TimetableConfiguration",
  data() {
    return {
      headers: [
        { text: this.$tc("concept", 1), value: "concepto" },
        { text: this.$t("from"), value: "d_start" },
        { text: this.$t("to"), value: "d_end" },
        { text: this.$t("artist"), value: "tattooer" },
        { text: this.$t("actions"), value: "actions" },
      ],
      headers_small: [
        { value: "concepto_tattooer" },
        { value: "d_start_d_end" },
      ],
      timetables: [],
      active: null,
      default_timetable: null,
    };
  },
  props: {
    tattooer_id: {
      type: Number,
    },

    box_id: {
      type: Number,
    },
    box_id: {
      type: Number,
    },
    studio_id: {
      type: Number,
    },
    icons: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetch();
  },
  components: {
    Add: () => import("./Add"),
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    ...mapActions("timetables", ["getTimetableResume", "getTimetables"]),
    fetch() {
      this.getTimetableResume({
        tattooer_id: this.tattooer_id,
        box_id: this.box_id,
        studio_id: this.studio_id,
        save_state: false,
      }).then((res) => {
        console.log(res);
        this.active = res.active.timetable;
        this.default_timetable = res.default.timetable;
      });
      this.fetchNexTattooers();
    },
    fetchNexTattooers() {
      this.getTimetables({
        pagination: {},
        filters: {
          tattooer_id: this.tattooer_id,
          box_id: this.box_id,
          studio_id: this.studio_id,
        },
        save_state: false,
      }).then((response) => {
        this.timetables = response;
      });
    },
  },
  computed: {
    type() {
      if (this.tattooer_id) return "tattooer";
      if (this.box_id) return "box";
      return "studio";
    },
  },
};
</script>
